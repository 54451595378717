<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Établissement.DIRECTION" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="8" lg="9" md="10" sm="11" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- Le détail de l'établissement -->
        <DetailViewBoxComponent>
          <template v-slot:title>
            <v-card-title class="font-weight-regular"
              ><v-row class="align-center justify-space-between no-gutters">
                <div>Détail de la partie DIRECTION d'un établissement</div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-progress-circular
                      :rotate="90"
                      :size="55"
                      :width="7"
                      :value="rate"
                      :color="rateColor"
                      v-on="on"
                      ><div class="caption font-weight-regular secondary--text">
                        {{ rate }}%
                      </div></v-progress-circular
                    >
                  </template>
                  <span>Taux de remplissage</span>
                </v-tooltip>
              </v-row>
            </v-card-title>
          </template>

          <template v-slot:items>
            <InputFieldComponent label="nom digital" :value="digitalName" />
            <InputFieldComponent label="nom interne" :value="internalName" />
            <InputFieldComponent
              label="nom commercial"
              :value="commercialName"
            />
            <InputFieldComponent label="statut" :value="status" />
            <InputFieldComponent
              label="structure juridique"
              :value="legalStructureName"
            />
            <InputFieldComponent label="email" :value="email" />
            <InputFieldComponent label="metier" :value="metierName" />
            <!-- Les activités et sous-activités -->
            <InputFieldComponent
              label="activités / sous-activités"
              alignLabel="start"
            >
              <template v-slot:value>
                <div
                  v-for="(activity, i) in activitiesSubactivities"
                  :key="'A' + i"
                >
                  <v-row no-gutters align="start" justify="start" class="mb-1">
                    <div class="mt-1 font-weight-black">
                      {{ activity.name }}
                    </div>
                  </v-row>

                  <div class="mr-2 ml-8">Site web :</div>
                  <div class="mb-1 ml-16 ma-0 pa-0">{{ activity.url }}</div>

                  <div class="mr-2 ml-8">Nom commercial :</div>
                  <div class="mb-1 ml-16 ma-0 pa-0">
                    {{ activity.commercialName }}
                  </div>

                  <div class="mr-2 ml-8">Téléphone accueil établissement :</div>
                  <div class="mb-1 ml-16 ma-0 pa-0">
                    {{ activity.phoneReception }}
                  </div>

                  <div class="mr-2 ml-8">Téléphone call-center :</div>
                  <div class="mb-1 ml-16 ma-0 pa-0">
                    {{ activity.phoneCallCenter }}
                  </div>

                  <div class="mr-2 ml-8">Id Google My Business :</div>
                  <div class="mb-1 ml-16 ma-0 pa-0">
                    {{ activity.idGoogleMyBusiness }}
                  </div>

                  <div class="mr-2 ml-8">Sous-activités :</div>
                  <div
                    v-for="(subActivity, j) in activity.subactivities"
                    :key="'B' + j"
                  >
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1 ml-16"
                    >
                      <div>{{ subActivity.digitalName }}</div>
                    </v-row>
                  </div>
                </div>
              </template>
            </InputFieldComponent>
            <InputFieldComponent label="adresse / rue" :value="addressStreet" />
            <InputFieldComponent
              label="adresse / complément d'adresse"
              :value="addressStreet2"
            />
            <InputFieldComponent
              label="adresse / code postal"
              :value="addressPostalCode"
            />
            <InputFieldComponent label="adresse / ville" :value="addressCity" />

            <InputFieldComponent label="Coordonnées GPS" alignLabel="start">
              <template v-slot:value>
                <v-row no-gutters align="center">
                  <v-col cols="8" justify="space-between" align-self="center">
                    <div class="capitalize font-weight-regular mb-2 py-2">
                      {{ addressLatitude }}
                    </div>
                    <div class="capitalize font-weight-regular mb-2 py-2">
                      {{ addressLongitude }}
                    </div>
                  </v-col>
                  <v-col cols="4" justify="space-between" align-self="right">
                    <v-btn
                      outlined
                      class="ma-2 px-4 py-6 btn"
                      color="primary"
                      @click="openMap"
                      height="60px"
                      :disabled="!canViewMap"
                    >
                      <div class="capitalize" style="white-space: normal">
                        voir sur la carte
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="département" :value="department" />
            <InputFieldComponent label="support par défaut" :value="support" />

            <InputFieldComponent
              label="supports spécifiques"
              alignLabel="start"
            >
              <template v-slot:value>
                <div v-for="(support, i) in supports" :key="i">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-between"
                    class="mb-1"
                  >
                    <v-col cols="6">
                      <div>{{ support.supportName }}</div>
                    </v-col>
                    <v-col cols="6">
                      <div>{{ support.functionName }}</div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="tel fixe" :value="phoneMain" />
            <InputFieldComponent
              label="tel établissement / standard"
              :value="phoneReception"
            />
            <InputFieldComponent label="tel astreinte" :value="phoneOncall" />
            <InputFieldComponent label="fax établissement" :value="fax" />
            <InputFieldComponent label="site" :value="webSite" />

            <InputFieldComponent
              label="Contacts locaux pour les offres d'emploi"
              alignLabel="start"
            >
              <template v-slot:value>
                <div v-for="(recipient, i) in jobOfferRecipients" :key="i">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-between"
                    class="mb-1"
                  >
                    <v-col cols="6">
                      <div>{{ recipient.label }}</div>
                    </v-col>
                    <v-col cols="6">
                      <div>{{ recipient.email }}</div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </InputFieldComponent>

            <!-- Le code urssaf -->
            <InputFieldComponent label="Code URSSAF (Centre de versement)" :value="urssafCode" />

            <!-- Le raw -->
            <InputFieldComponent
              v-for="(field, k) in raw"
              :key="'C' + k"
              :label="field.label"
              :value="field.data"
              :divider="!(k == raw.length - 1)"
            />
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <v-layout column class="fab-container">
      <v-btn
        class="mx-2 my-2"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToTop"
        color="primary"
        @click="toTop"
      >
        <v-icon :disabled="!canScrollToTop">mdi-chevron-up</v-icon>
      </v-btn>

      <v-btn
        class="mx-2 my-2"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToBottom"
        @click="toBottom"
        color="primary"
      >
        <v-icon :disabled="!canScrollToBottom">mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>

    <!-- Fenetre modale pour la visualisation/modification de la géoloc de l'établissement -->
    <DialogMap
      :visible.sync="showDialogMap"
      :addressProp="addressStreet"
      :postalCodeProp="addressPostalCode"
      :cityProp="addressCity"
      :latitude="addressLatitude"
      :longitude="addressLongitude"
      :onlyReadMode="true"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";
import { JobOfferRecipientService } from "@/service/etablishment/job_offer_recipient_service.js";

import PropertiesMapping from "@/service/etablishment/properties_mapping.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import DialogMap from "../dialogs/dialogMap.vue";

import * as log from "@/tools/logger.js";

import * as stringTools from "@/tools/string_tool.js";

const _HYPERLINK = "HYPERLINK";
const _FIELD_NAME_NUM_ASSURANCE = "numeroContratAssuranceEtablissement";
const _FIELD_NAME_LINK_NUM_ASSURANCE = "lienVersNumeroContrat";

export default {
  name: "DetailEstablishment",

  components: {
    Workflow,
    TitleAndReturnComponent,
    DetailViewBoxComponent,
    InputFieldComponent,
    DialogMap,
  },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** les services. */
      serviceEstablishments: null,
      serviceActivities: null,
      serviceMetiers: null,
      serviceLegalStructure: null,
      serviceFunction: null,
      jobOfferRecipientService: null,

      /**l'identifiant  à visualiser. */
      establishmentId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /** Le nom de l'établissement */
      digitalName: null,
      /** Le nom commercial */
      commercialName: null,
      /** le nom interne */
      internalName: null,
      /** le status (exploité, fermé, ...) */
      status: null,
      /** la structure juridique de référence */
      legalStructureName: null,
      /** l'email de l'établissement */
      email: null,
      /** le métier associé */
      metierName: null,
      /** les activités et sous-activités */
      activitiesSubactivities: [],
      /** l'adresse */
      addressStreet: null,
      addressStreet2: null,
      addressPostalCode: null,
      addressCity: null,
      addressLatitude: 0,
      addressLongitude: 0,
      /** le departement */
      department: null,
      /** l'établissement support */
      support: null,
      /** les numéro de téléphone */
      phoneMain: null,
      phoneReception: null,
      phoneOncall: null,
      fax: null,
      idGoogleMyBusiness: null,
      /** le site web */
      webSite: null,
      /** le code urssaf */
      urssafCode: null,
      /** les supports couple estab/function */
      supports: null,
      /** les destinataires des offres d'empli */
      jobOfferRecipients: null,

      /** Les données du raw */
      raw: [],

      /**le taux de remplissage */
      rate: 0.0,

      /** Etat de visualisation de la carte */
      showDialogMap: false,

      /**Boutons pour scroller tout en bas et tout en haut  */
      canScrollToTop: false,
      canScrollToBottom: true,
    };
  },
  methods: {
    async load() {
      try {
        log.debug("Load ressource on Détails establishment.");
        this.loading = true;

        /** Récupération des datas des établissements */
        const entity = await this.serviceEstablishments.getById(
          this.establishmentId
        );

        /** Récupération de la structure juridique par l'id dans l'établissement*/
        let legalStructureName = "-";
        if (entity.legalStructure) {
          const legalStructure = await this.serviceLegalStructure.getById(
            entity.legalStructure
          );
          if (legalStructure) {
            legalStructureName = legalStructure.digitalName;
          }
        }
        entity.legalStructureName = legalStructureName;

        /** Récupération de l'établissement support */
        let entitySupport = "-";
        if (entity.support) {
          const support = await this.serviceEstablishments.getById(
            entity.support
          );
          if (support) {
            entitySupport = support.digitalName;
          }
        }
        entity.supportName = entitySupport;

        await this.loadActivities(entity);

        /** Récupération du métier de l'établissement */
        let metierName = "-";
        if (entity.metierId) {
          const metier = await this.serviceMetiers.getById(entity.metierId);
          metierName = metier.name;
        }
        entity.metierName = metierName;

        // Récupération de l'ensemble des fonctions
        const allFunctions = await this.serviceFunction.getAll();
        // Récupération de l'ensemble des noms digitaux des établissements
        const establishments =
          await this.serviceEstablishments.getDigitalNames();
        // Récupération de l'ensemble des couple estab/funtion pour l'établissement
        const supports =
          await this.serviceEstablishments.getAllEstablishmentFunctionForEstablishment(
            this.establishmentId
          );
        // Parcours de l'ensemble des des supports
        for (const support of supports) {
          // Récupération de la fonction
          const func = allFunctions.find((f) => f.id == support.functionId);
          // Récupération de l'établissement
          const estab = establishments.find((e) => e.id == support.supportId);

          support.functionName = func.name;
          support.supportName = estab.digitalName;
        }
        this.supports = supports;

        // Chargement des destinataires pour les offres d'emploi
        this.jobOfferRecipients = await this.jobOfferRecipientService.getAll(
          this.establishmentId
        );

        //
        // la partie RAW
        //
        await this.loadRawPart();

        //on met à jour le taux de remplissage
        const dataRate = this.computeDataRate(entity);
        const rawRate = this.computeRawRate(this.raw);

        // le nombre total de champs
        let count = dataRate.count + rawRate.count;
        // le nombre total de champs complété
        let filled = dataRate.filled + rawRate.filled;

        //on compte l'activité
        count++;
        if (this.activitiesSubactivities.length > 0) {
          filled++;
        }

        if (count > 0) {
          this.rate = (100 * (filled / count)).toFixed(1);
        } else {
          this.rate = 0.0;
        }

        this.init(entity);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async loadActivities(entity) {
      /** Récupération de l'ensemble des activités de la bd */
      const allActivities = await this.serviceActivities.getActivities();

      /** Matching entre la liste des activités de la bd et la liste des activités de l'établissement */
      const activities = allActivities.filter((a) =>
        entity.activityIds.find((i) => i == a.id)
      );
      /** Récupération des urls des actvités de l'établissement */
      const urls = await this.serviceEstablishments.getUrlOFActivities(
        entity.id
      );
      // Récupération des sousactivitées associées à chaque activité
      for (const activity of activities) {
        const subactivities =
          await this.serviceActivities.getSubActivitiesOfActivity(activity.id);

        // Récupération des sous-activités qui matchent avec celles de l'établissement
        const subs = subactivities.filter((sub) =>
          entity.subActivityIds.find((s) => s == sub.id)
        );

        activity.subactivities = [];
        activity.subactivities = subs;

        const urlAct = urls.find((u) => u.activityId == activity.id);
        if (urlAct) {
          activity.url = urlAct.website || "-";
          activity.commercialName = urlAct.commercialName || "-";
          activity.phoneCallCenter = urlAct.phoneCallCenter || "-";
          activity.phoneReception = urlAct.phoneReception || "-";
          activity.idGoogleMyBusiness = urlAct.idGoogleMyBusiness || "-";
        } else {
          activity.url = "-";
          activity.commercialName = "-";
          activity.phoneCallCenter = "-";
          activity.phoneReception = "-";
          activity.idGoogleMyBusiness = "-";
        }
      }
      this.activitiesSubactivities = [...activities];
    },

    async loadRawPart() {
      //on charge le RAW de l'établissement cible
      const raw = await this.serviceEstablishments.getRawById(
        this.establishmentId
      );

      const tool = new PropertiesMapping();
      const mapping = tool.getMapping();

      //on prépare les champs à utiliser

      const rawFields = [];

      mapping.forEach((m) => {
        if (m.isRaw) {
          // Création d'un champ pour la propriétée mappée
          rawFields.push({
            label: m.label,
            property: m.property,
            order: m.order,
            hint: m.hint,
            isRaw: m.isRaw,
            na: false,
            data: "-",
          });
          // Si m est 'contrat d'assurance' on ajoute le champ pour la saisie du lien.
          if (m.property === _FIELD_NAME_NUM_ASSURANCE) {
            const link = tool.getLinkContratNumber();
            rawFields.push({
              label: link.label,
              property: link.property,
              order: link.order,
              hint: link.hint,
              isRaw: link.isRaw,
              na: false,
              data: "-",
            });
          }
        }
      });

      // pour chaque champs à afficher
      rawFields.forEach((e) => {
        e.na = false;
        // raw is null ?
        if (raw) {
          // le champ est le lien vers le num contrat, on récupère la data hyperlink
          if (e.property === _FIELD_NAME_LINK_NUM_ASSURANCE) {
            e.data =
              this.splitHyperlink(raw[_FIELD_NAME_NUM_ASSURANCE]).link || "-";
          } else if (raw[e.property]) {
            // Si c'est la propriété du numero de contrat de travail, on récupère le texte.
            if (e.property === _FIELD_NAME_NUM_ASSURANCE) {
              // Récupération du texte
              e.data =
                stringTools.replaceNullOrUndefineByHyphen(
                  this.splitHyperlink(raw[e.property]).text
                ) || "-";
            } else {
              e.data =
                stringTools.replaceNullOrUndefineByHyphen(raw[e.property]) ||
                "-";
            }
          }
          // si champ à n/a, activaton
          if (e.data === "n/a") {
            e.na = true;
          }
        }
      });

      this.raw = [...rawFields];
    },

    initAddress(entity) {
      if (entity.address) {
        this.addressStreet = entity.address.address || "-";
        this.addressStreet2 = entity.address.address2 || "-";
        this.addressPostalCode = entity.address.postalCode || "-";
        this.addressCity = entity.address.city || "-";
        this.addressLatitude = entity.address.latitude || 0;
        this.addressLongitude = entity.address.longitude || 0;
        this.department = entity.departement || "-";
      } else {
        this.addressStreet = "-";
        this.addressStreet2 = "-";
        this.addressPostalCode = "-";
        this.addressCity = "-";
        this.addressLatitude = 0;
        this.addressLongitude = 0;
        this.department = "-";
      }
    },
    // Initialisation des variables de la vue pour l'établissement
    init(entity) {
      this.digitalName = entity.digitalName || "-";
      this.commercialName = entity.commercialName || "-";
      this.internalName = entity.internalName || "-";
      this.status = entity.status || "-";
      this.legalStructureName = entity.legalStructureName || "-";
      this.email = entity.email || "-";
      this.metierName = entity.metierName || "-";
      this.initAddress(entity);

      this.support = entity.supportName || "-";
      this.phoneMain = entity.phoneMain || "-";
      this.phoneReception = entity.phoneReception || "-";
      this.phoneOncall = entity.phoneOncall || "-";
      this.fax = entity.fax || "-";
      this.webSite = entity.website || "-";
      this.idGoogleMyBusiness = entity.idGoogleMyBusiness || "-";
      this.urssafCode = entity.urssafCode || "-";
    },

    /** Splitte le lien hyperlink donné en paramètre en 2 donnée : texte et lien */
    splitHyperlink(link) {
      let text = "";
      let linkField = "";

      if (link) {
        // si la data contient hyperlink
        if (link.includes(_HYPERLINK)) {
          const split = link.split('"');

          text = split[3];
          linkField = split[1];
        } else {
          // s'il n'y a pas "HYPERLINK" le texte est le link
          text = link;
          if (text === "n/a") {
            linkField = text;
          }
        }
      }
      // Retour d'un objet contenant le texte et le link.
      return { text: text, link: linkField };
    },
    /**calculer le taux de remplissage pour les données brutes */
    computeDataRate(entity) {
      //les items : address, city, postalCode, digitalName,commercialName,
      //  internalName, status, support, email, departement
      const count = 19;
      let filled = 0;

      if (!stringTools.isNullOrEmpty(entity.digitalName)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.commercialName)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.internalName)) {
        filled++;
      }
      if (entity.status) {
        filled++;
      }
      if (entity.legalStructure) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.email)) {
        filled++;
      }
      if (entity.metierId) {
        filled++;
      }
      filled += this.computeAddressDataRate(entity);
      if (!stringTools.isNullOrEmpty(entity.departement)) {
        filled++;
      }
      if (entity.support) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.phoneMain)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.phoneReception)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.idGoogleMyBusiness)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.phoneOncall)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(entity.fax)) {
        filled++;
      }

      return { count, filled };
    },
    computeAddressDataRate(entity) {
      let filled = 0;
      if (entity.address) {
        if (!stringTools.isNullOrEmpty(entity.address.address)) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(entity.address.postalCode)) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(entity.address.city)) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(entity.address.latitude)) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(entity.address.longitude)) {
          filled++;
        }
      }
      return filled;
    },
    /**calculer le taux de remplissage pour les données RAW */
    computeRawRate(rawFields) {
      const count = rawFields.length;

      const missings = rawFields.filter(
        (field) => stringTools.isNullOrEmpty(field.data) || field.data === "-"
      ).length;

      return { count, filled: count - missings };
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const currentPos = window.pageYOffset || e.target.scrollTop || 0;
      const bottom = document.body.scrollHeight - window.innerHeight;
      this.canScrollToTop = currentPos > bottom * 0.15;
      this.canScrollToBottom = currentPos < bottom * 0.85;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    toBottom() {
      this.$vuetify.goTo(document.body.scrollHeight);
    },

    /** Ouverture de la fenetre pour la géoloc de l'adresse de l'établissement */
    openMap() {
      this.showDialogMap = true;
    },
  },
  computed: {
    /**Retourne la couleur à afficher en fonction du taux de remplissage. */
    rateColor() {
      return "primary";
    },

    /** Possibilité de voir la carte */
    canViewMap() {
      return (
        (this.addressStreet && this.addressPostalCode && this.addressCity) ||
        (this.addressLatitude && this.addressLongitude)
      );
    },
  },
  mounted() {
    this.serviceEstablishments = new EstablishmentsService(this.$api);
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());
    this.serviceLegalStructure = new LegalStructureService(
      this.$api.getLegalStructuresApi()
    );
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.jobOfferRecipientService = new JobOfferRecipientService(
      this.$api.getJobOfferRecipientApi()
    );

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.establishmentId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
.fab-container {
  margin: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
